import { useQuery } from '@tanstack/vue-query';
import { useSessionsStore } from '@/store/sessions.store';
import apiClient from '@/apiClient';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { config, getConfigCardProgramId } from '@/config';
import {
  KycPrerequisiteStatus,
  KycPrerequisitesResponseItem,
  PrerequisitesResponseItemType
} from '@/apiClient/types/prerequisite';
import { useFundingSource } from './fundingSourceHook';
import { useAccountActivationStore } from '../store/accountActivation.store';
import { KycStatus } from '@/types/kycStatus';

export const usePrerequisites = () => {
  useFundingSource();

  const sessionsStore = useSessionsStore();

  const activationDetailsStore = useAccountActivationStore();

  const { currentSession } = storeToRefs(sessionsStore);

  const cardProgramId = getConfigCardProgramId();

  function setKycStatus(value: KycStatus) {
    activationDetailsStore.setKycStatus(value);
  }

  function setKycURl(value: string) {
    activationDetailsStore.setKycUrl(value);
  }

  const {
    data: prerequisites,
    isLoading,
    error,
    refetch: refetchPrerequisites
  } = useQuery({
    queryKey: ['list-spending-prerequisites', currentSession.value?.fundingSourceId],
    queryFn: async () => {
      if (!currentSession.value?.fundingSourceId) {
        return {
          prerequisites: []
        };
      }
      const BASE_URL = config.API_BASE_URL;
      const prerequisitesResponse = await apiClient.listSpendingPrerequisites({
        cardProgramId,
        fundingSourceId: currentSession.value?.fundingSourceId || '',
        spendableAmount: '1',
        spendableCurrency: 'USD',
        kycType: 'immersve-conducted',
        kycRedirectUrl: `${BASE_URL}/dashboard`,
        kycRegion: config.KYC_REGION || undefined,
        kycHiddenSteps: config.KYC_HIDE_STEPS_ENABLED
          ? ['region', 'contact-channels', 'expected-spend', 'residential-address']
          : undefined
      });

      return prerequisitesResponse;
    },
    select(data) {
      return data.prerequisites;
    },
    //calculates the time until the next fetch of data
    refetchInterval(prerequisites) {
      // doesn't refetch when returns false. The watcher retriggers this when it's updated.
      if (!currentSession.value?.fundingSourceId) {
        return false;
      }

      const kycPrerequisite = prerequisites?.find(
        (p) => p.type === PrerequisitesResponseItemType.KYC
      ) as KycPrerequisitesResponseItem | undefined;

      if (!kycPrerequisite) {
        return false;
      }

      const defaultPollingIntervalInMillis = 10000;

      return defaultPollingIntervalInMillis;
    },
    enabled: true
  });

  watch(
    () => currentSession.value?.fundingSourceId,
    (fundingSourceId) => {
      if (!fundingSourceId) {
        return;
      }
      refetchPrerequisites();
    }
  );

  watch(prerequisites, (newPrerequisites) => {
    const kycPrerequisite = newPrerequisites?.find(
      (p) => p.type === PrerequisitesResponseItemType.KYC
    ) as KycPrerequisitesResponseItem;

    if (!kycPrerequisite) {
      setKycStatus(KycStatus.PASSED);
      return;
    }

    switch (kycPrerequisite.params.status) {
      case KycPrerequisiteStatus.KYC_REQUIRED:
        setKycStatus(KycStatus.REQUIRED);
        break;
      case KycPrerequisiteStatus.KYC_CHECK_FAILED:
        setKycStatus(KycStatus.FAILED);
        break;
      case KycPrerequisiteStatus.CHECK_IN_PROGRESS:
        setKycStatus(KycStatus.PENDING);
        break;
      default:
        break;
    }

    if (kycPrerequisite.params.kycUrl) {
      setKycURl(kycPrerequisite.params.kycUrl);
    }
  });

  return {
    isLoading,
    error,
    prerequisites,
    refetchPrerequisites
  };
};
